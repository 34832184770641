import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import FsLightbox from 'fslightbox-react';
import { Element } from 'react-scroll';
import { FaPlay } from 'react-icons/fa';

const JobseekersBanner = () => {
  const [toggler, setToggler] = useState(false);
  const videoLink = ['https://www.youtube.com/embed/lSi2H_ULp4s?autoplay=1&rel=0'];

  return (
    <Element
      name="datatalent-banner"
      className="data-talent-jobseekers-banner banner-style-1 bg-image height-640 d-flex align-items-center"
    >
      <div className="container eduvibe-animated-shape">
        <div className="row g-5 row--40 align-items-center">
          <div className="order-1 order-xl-1 col-lg-12 col-xl-7">
            <div className="banner-left-content mt--40">
              <div className="inner">
                <div className="content position-relative">
                  <h1 className="datatalent-program-title title">DataTalent Program</h1>
                  <p className="description">Build your career in data analytics. Join our <b>16 week program</b> that delivers industry-level advanced online course bundles focused on Data Science and Machine Learning.<br></br>
                  <br></br>You will have the opportunity to dive into an Employer Led Work-integrated Learning project, gaining real-world experience and networking with top industry experts. This program also includes a <b>paid $500 stipend</b> upon completion.</p>
                  <span className="datatalent-description-span">*eligibility requirements below</span>
                  <div className="read-more-btn banner-btns mt--40">
                    {/* <button className="edu-btn" onClick={() => setShowEmployerRegisterForm(true)}>Employer Login</button>
                    <button className="edu-btn btn-secondary" onClick={() => setShowUserInterestForm(true)}>Jobseekers Login</button> */}
                    <a className="edu-btn btn-primary" href="https://form.jotform.com/m2mtech/cohort-sign-up">Register Now</a>
                    <Link className="edu-btn btn-bg-alt" to="/contact-us">Book Info Call</Link>
                  </div>
                  <p className="datatalent-partners-header mt--40 mb-0"><strong>Partnered With</strong>:</p>
                  <div className="datatalent-partners row align-items-center mx-0">
                    <a className="d-flex justify-content-start p-0" href="https://www.canada.ca/en.html"><img src={`${process.env.PUBLIC_URL}/images/logo/govt_of_canada.svg`} width="50%" alt="logo" loading="lazy" /></a>
                    <a className="d-flex justify-content-start p-0" href="https://paletteskills.org/upskill-canada"><img src={`${process.env.PUBLIC_URL}/images/logo/upskill_canada_logo_v3.png`} width="75%" alt="logo" loading="lazy" /></a>
                    <a className="d-flex justify-content-start p-0" href="https://www.riipen.com/"><img src={`${process.env.PUBLIC_URL}/images/sponsors/riipen_logo.png`} width="50%" alt="logo" loading="lazy" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-xl-2 order-xl-3"></div> */}
          <div className="order-2 order-xl-3 col-lg-12 col-xl-5 d-flex flex-column justify-content-center banner-right-content">
            <div className="feature-thumbnail">
              <div className="main-image video-popup-wrapper video-popup-two">
                <img src={`${process.env.PUBLIC_URL}/images/videopopup/pexels-monstera-6238170_v2.png`} alt="Video PopUp Thumb" loading="lazy" />

                <button className="video-play-btn with-animation position-to-top btn-large video-popup-activation eduvibe-video-play-icon color-secondary" onClick={ () => setToggler( ! toggler ) }><span><FaPlay className="play-icon" /></span></button>
              </div>
            </div>
            <div className="datatalent-stats-container d-flex align-items-start justify-content-center">
              <div className="col-4 datatalent-stats-courses">
                <p><strong>Available courses</strong></p>
                <span className="datatalent-description-span">Our expansive library will prepare you for a creer in the data science industry.</span>
              </div>
              <div className="col-4 datatalent-stats-ratings">
                <p><strong>Ratings</strong></p>
                <span className="datatalent-description-span">xxx participants have completed DataTalent.</span>
              </div>
              <div className="col-4 datatalent-stats-schedule">
                <p><strong>Flexible Schedule</strong></p>
                <span className="datatalent-description-span">Fully online and accessible anywhere, complete the program at your place.</span>
              </div>
            </div>
          </div>
        </div>
        <div className="video-lightbox-wrapper">
          <FsLightbox
            toggler={ toggler } 
            sources={ [ <iframe src={videoLink} style={{"width": "960px"}} height="540px" frameBorder="0" title="Youtube video player" allow="autoplay; fullscreen" /> ] }
            maxYoutubeVideoDimensions={ { width: 960, height: 540 } }
          />
        </div>
      </div>
    </Element>
  )
}

export default JobseekersBanner;