import React from 'react';
import DataTalentSectionTitle from './DataTalentSectionTitle';
import DataTalentTestimonial from './DataTalentTestimonial';

const DataTalentTestimonialSection = () => {
    return (
        <div className="eduvibe-testimonial-dataTalent edu-testimonial-area edu-section-gap bg-image">
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                        <DataTalentSectionTitle
                            classes = "text-center"
                            slogan = "Testimonials"
                            title = "What DataTalent Participants Say About Us"
                        />
                    </div>
                </div>
                <div className="row g-5 mt--25">
                    <div className="col-lg-12 col-12 mt-0">
                        <DataTalentTestimonial item="13" />
                    </div>
                </div>
                {/* <a className="edu-btn" href="https://www.google.com/search?q=m2m+tech&rlz=1C1CHBF_enCA1045CA1045&oq=m2m+tech&aqs=chrome.0.0i355i512j46i175i199i512j0i512l3j69i60l3.3993j0j7&sourceid=chrome&ie=UTF-8#">Get Started<i className="icon-arrow-right-line-right"></i></a> */}

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-13.png`} alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-13-01.png`} alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-04.png`} alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-4">
                        <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-02-01.png`} alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DataTalentTestimonialSection;