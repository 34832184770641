import React from 'react';
import { Link } from 'react-router-dom';

const DataTalentFooter = () => {
  return (
    <>
      <div className="edu-service-area service-wrapper-1 datatalent-section-gap bg-image">
        <div className="container eduvibe-animated-shape datatalent-footer">
          <div className="row g-5 position-relative">
            <div className="col-lg-7">
              <h4>Ready to take the next step in your career and become a Data Science Expert?</h4>
              <p>Unsure if DataTalent is the right program for you?</p>
              <Link to="/" className="d-flex gap-2"><span className="highlight-text-primary"><em>Chat with one of our program<br></br>coordinators to learn more</em></span> <img src={`${process.env.PUBLIC_URL}/images/icons/arrow-right.svg`} alt="right arrow" /></Link>
            </div>
            <div className="col-lg-5 d-flex justify-content-center justify-content-lg-end align-items-center">
              <a className="edu-btn datatalent-cta" href="https://form.jotform.com/m2mtech/cohort-sign-up">Register Now</a>
            </div>
            <p className="m2m">M2M</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default DataTalentFooter;