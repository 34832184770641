import React from 'react'
import SEO from '../../../common/SEO';
import HeaderTwo from '../../../common/header/HeaderTwo';
import FooterOne from '../../../common/footer/FooterOne';
import JobseekersBanner from '../../../components/data-talent/data-talent-jobseekers/JobseekersBanner';
import JobseekersAbout from '../../../components/data-talent/data-talent-jobseekers/JobseekersAbout';
import DataTalentTestimonialSection from '../../../components/data-talent/data-talent-jobseekers/DataTalentTestimonialSection';
import DataTalentCapstoneProjects from '../../../components/data-talent/data-talent-jobseekers/DataTalentCapstoneProjects';
import DataTalentProgramOverview from '../../../components/data-talent/data-talent-jobseekers/DataTalentProgramOverview';
import DataTalentFooter from '../../../components/data-talent/data-talent-jobseekers/DataTalentFooter';

const DataTalentJobseekers = () => {
  return (
    <>
      <SEO
				title="DataTalent for Jobseekers"
				description="Unlock 80 hours, fully subsidized, Digital Tech internships for Canadian employers, to build DataTalent pipeline using our Upskilled Talents."
      />
      <HeaderTwo styles="header-transparent header-style-2" searchDisable />
      <JobseekersBanner />
      <JobseekersAbout />
      <DataTalentProgramOverview />
      <DataTalentTestimonialSection />
      <DataTalentCapstoneProjects />
      <DataTalentFooter />
      <FooterOne />
    </>
  )
}

export default DataTalentJobseekers;