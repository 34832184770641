import React, { useState } from 'react';
import DataTalentSectionTitle from '../DataTalentSectionTitle';
import { Element } from 'react-scroll';
// import ScrollAnimation from 'react-animate-on-scroll';
import FsLightbox from 'fslightbox-react';
import { FaPlay } from 'react-icons/fa';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
// import { DataTalentCapstoneProjectVideos } from '../../DataTalentCapstoneProjectVideos';

const DataTalentCapstoneProjects = () => {
  const [toggler1, setToggler1] = useState(false);
  const [toggler2, setToggler2] = useState(false);
  const [toggler3, setToggler3] = useState(false);
  // const [toggler4, setToggler4] = useState(false);
  // const [toggler5, setToggler5] = useState(false);
  // const [toggler6, setToggler6] = useState(false);

  const video1 = ['https://www.youtube.com/embed/hGOmAKCI5VY?autoplay=1&rel=0'];
  const video2 = ['https://www.youtube.com/embed/adubOrTZcvc?autoplay=1&rel=0'];
  const video3 = ['https://www.youtube.com/embed/3dM3sSpY9sI?autoplay=1&rel=0'];
  // const video4 = ['https://www.youtube.com/embed/tRDtXcgZaGI?autoplay=1&rel=0'];
  // const video5 = ['https://www.youtube.com/embed/dpXJug2h80g?autoplay=1&rel=0'];
  // const video6 = ['https://www.youtube.com/embed/ZQ2iYJOcyPs?autoplay=1&rel=0'];
  // const [currentVideoID, setCurrentVideoID] = useState();
  // const [updatedArray, setUpdatedArray] = useState(DataTalentCapstoneProjectVideos);

  // const handleToggler = (id) => {
  //   // setToggler( ! toggler );
  //   setCurrentVideoID(id);    
  // }

  // useEffect(() => {
  //   let foundObject = updatedArray.find(item => item.id === currentVideoID);
  //   if (foundObject) {
  //     foundObject.toggler = true;
  //     console.log("found", foundObject);
  //     updatedArray.map(item => (item.toggler = false));
  //     // setUpdatedArray(updatedArray.map(item => (item.id === foundObject.id ? foundObject : item)));
  //   } 
  // }, [currentVideoID, updatedArray])

  const items = [
    {
      id: 1,
      title: 'AI Prototype for Public Sector',
      info: 'The goal of the project is to build a prototype of an AI-based tool that can be used by public sector organizations to predict service needs based on client characteristics and results.',
      numberOfCourses: '12 weeks',
      modeOfCourses: 'Anywhere',
      category: 'Data Science, ML & AI',
      image: 'project-details/public_sector_AI_v2_thumbnail.webp',
      link: '/programs/industry-work-integrated-learning/ai-prototype-for-public-sector'
    },
    {
      id: 2,
      title: 'Artificial intelligence for speech enhancement',
      info: 'This project aims to use data collection with field work to create a model to improve the performance of RMK mics, a device used to receive and deliver sound.',
      numberOfCourses: '12 weeks',
      modeOfCourses: 'Anywhere',
      category: 'Data Science, ML & AI',
      image: 'project-details/AI_speech_enhancement_v2_thumbnail.webp',
      link: '/programs/industry-work-integrated-learning/artificial-intelligence-for-speech-enhancement'
    },
    {
      id: 3,
      title: 'Convert Chat Threads into a Vector Database',
      info: 'The goal of this project is to apply the latest AI & ML techniques to an existing dataset to create an AI/ML model that supports development of more content and user access with the platform\'s evolution.',
      numberOfCourses: '12 weeks',
      modeOfCourses: 'Anywhere',
      category: 'Data Science, ML & AI',
      image: 'project-details/AIChat_db_thumbnail.webp',
      link: '/programs/industry-work-integrated-learning/convert-chat-threads-into-a-vector-database'
    },
    {
      id: 4,
      title: 'AI & ML for Sports Data Site',
      info: 'Development of an AI / ML model related to projecting player and team performance, or findings other insights in the data so that it can be added to the data directory in SportWise.',
      numberOfCourses: '12 weeks',
      modeOfCourses: 'Anywhere',
      category: 'Data Science, ML & AI',
      image: 'project-details/AI_sports_data_thumbnail.webp',
      link: '/programs/industry-work-integrated-learning/aritificial-intelligence-and-machine-learning-for-sports-data-site'
    }
  ];

  let settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 5000,
    slidesPerRow: 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    arrows: true,
    // centerMode: true,
  };

  return (
    <>
      <Element
        name="CapstoneProjects"
        className="edu-about-area about-style-1 datatalent-section-gap home-one-cat"
      >
        <div className="container inner">
          <div className="row g-5 align-items-center datatalent-showcases">
            <div className="col-lg-12">
              <DataTalentSectionTitle
                classes = "text-left"
                slogan = "Project Showcase"
                title = "Capstone Projects Of Our DataTalent Participants!"
              />
            </div>
            <div className="col-lg-12 mt-4 data-talent-description ms-lg-5">
              <p className="mb-0">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Suscipit quae laboriosam aliquam sapiente. Nisi ratione assumenda necessitatibus beatae corporis qui, exercitationem, illo ut sequi eveniet aperiam, deleniti fugit neque sapiente?</p>
            </div>
            <Slider {...settings} className="testimonial-activation edu-slick-button slick-button-center mt-0 mb-3">
              <div className="col-12 col-md-4 mt--40 d-flex flex-column align-items-center">
                <div className="main-image video-popup-wrapper">
                  <img src={`${process.env.PUBLIC_URL}/images/data-talent/capstone-projects/data_analysis_and_manipulation_v2.png`} alt="Video PopUp Thumb" loading="lazy" />
                  <button className="video-play-btn with-animation position-to-top btn-large video-popup-activation eduvibe-video-play-icon color-secondary" onClick={() => setToggler1( !toggler1 ) }><span><FaPlay className="play-icon" /></span></button>
                </div>
                <h5>Data Analysis & Manipulation | Penelope Haro</h5>
              </div>
              <div className="col-12 col-md-4 mt--40 d-flex flex-column align-items-center">
                <div className="main-image video-popup-wrapper">
                  <img src={`${process.env.PUBLIC_URL}/images/data-talent/capstone-projects/data_analysis_and_visualization_v2.png`} alt="Video PopUp Thumb" loading="lazy" />
                  <button className="video-play-btn with-animation position-to-top btn-large video-popup-activation eduvibe-video-play-icon color-secondary" onClick={() => setToggler2( !toggler2 ) }><span><FaPlay className="play-icon" /></span></button>
                </div>
                <h5>Data Analysis & Visualization | Farzad</h5>
              </div>
              <div className="col-12 col-md-4 mt--40 d-flex flex-column align-items-center">
                <div className="main-image video-popup-wrapper">
                  <img src={`${process.env.PUBLIC_URL}/images/data-talent/capstone-projects/predicting_concrete_strength_v3.png`} alt="Video PopUp Thumb" loading="lazy" />
                  <button className="video-play-btn with-animation position-to-top btn-large video-popup-activation eduvibe-video-play-icon color-secondary" onClick={() => setToggler3( !toggler3 ) }><span><FaPlay className="play-icon" /></span></button>
                </div>
                <h5>Predicting Concrete Strength | Sola</h5>
              </div>
            </Slider>            
            <div className="video-lightbox-wrapper m-0">
              <FsLightbox
                toggler={ toggler1 }
                sources={ [ <iframe src={video1} style={{"width": "960px"}} height="540px" frameBorder="0" title="Youtube video player" allow="autoplay; fullscreen" /> ] }
                maxYoutubeVideoDimensions={ { width: 960, height: 540 } }
              />
            </div>
            <div className="video-lightbox-wrapper">
              <FsLightbox
                toggler={ toggler2 }
                sources={ [ <iframe src={video2} style={{"width": "960px"}} height="540px" frameBorder="0" title="Youtube video player" allow="autoplay; fullscreen" /> ] }
                maxYoutubeVideoDimensions={ { width: 960, height: 540 } }
                />
            </div>
            <div className="video-lightbox-wrapper">
              <FsLightbox
                toggler={ toggler3 }
                sources={ [ <iframe src={video3} style={{"width": "960px"}} height="540px" frameBorder="0" title="Youtube video player" allow="autoplay; fullscreen" /> ] }
                maxYoutubeVideoDimensions={ { width: 960, height: 540 } }
              />
            </div>
            <div className="view-more-btn text-center mt-0">
              <a className="edu-btn" href="https://www.youtube.com/@M2MTechConnect" rel="noreferrer" target="_blank">View More<i className="icon-arrow-right-line-right"></i></a>
            </div>
            <div className="col-12 mt--80">
              <DataTalentSectionTitle
                classes = "text-left"
                slogan = "WIL Examples"
                title = "Industry Partner Work Integrated Learning"
              />
            </div>
            <div className="col-lg-12 mt-4 data-talent-description ms-lg-5">
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Suscipit quae laboriosam aliquam sapiente. Nisi ratione assumenda necessitatibus beatae corporis qui, exercitationem, illo ut sequi eveniet aperiam, deleniti fugit neque sapiente?</p>
            </div>
            <Slider {...settings} className="testimonial-activation edu-slick-button slick-button-center mt-0 mb-5">
              { items.map( ( data , i ) => (
                <div key={data.id} className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-column align-items-center">
                  <div className="service-card program-service-card service-card-1 radius-small">
                    <div className="inner row">
                      <div className="thumbnail col-lg-6 p-0">
                        <a href={ data.link }>
                          <img src={`${process.env.PUBLIC_URL}/images/${ data.image }`} style={{height: "100%"}} alt="Category Thumb" loading="lazy" />
                        </a>
                      </div>
                      <div className="content program-info col-lg-6">
                        <div className="program-info-content d-flex justify-content-between">
                          <span className="course-total"><i className="icon-time-line"></i> { data.numberOfCourses }</span>
                          <span className="course-total"><i className="icon-presentation"></i> { data.category }</span>
                        </div>
                        <h6 className="title mt-3"><a href={ data.link }>{ data.title }</a></h6>
                        <p className="description mt-4">{ data.info }</p>
                        <div className="d-flex justify-content-between flex-wrap mt-3">
                          {data.form ? <a className="edu-btn btn-secondary mt-3" href="https://form.jotform.com/m2mtech/wil-programs-sign-up">Register Now<i className="icon-arrow-right-line-right"></i></a> : <a href="/" style={{visibility : "hidden"}}>No link</a>}
                          <Link className="edu-btn btn-bg-alt mt-3" to={ data.link }>Find Out More<i className="icon-arrow-right-line-right"></i></Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) ) }
            </Slider>
          </div>
          <div className="shape shape-6 about-parallax-2 d-xl-block d-none">
            <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-12.png`} alt="Shape Thumb" loading="lazy" />
          </div>
        </div>
      </Element>
    </>
  )
}

export default DataTalentCapstoneProjects;