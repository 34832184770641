import React from 'react';
import DataTalentSectionTitle from '../DataTalentSectionTitle';
import { Element } from 'react-scroll';

const JobseekersAbout = () => {

  return (
    <>
      <Element className="edu-about-area bg-white datatalent-section-gap">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12">
              <DataTalentSectionTitle
                classes = "text-left"
                slogan = "Introduction"
                title = "About DataTalent"
              />
            </div>
            <div className="col-lg-8 mt-4">
              <h6 className="m-0">
                DataTalent is the premier data science program<br></br>
                for those who intend on <span className="highlight-text-primary">breaking into the tech industry</span><br></br> 
                or if you want to take the next step in your <span className="highlight-text-primary">professional development</span>.
              </h6>
              <div className="datatalent-steps-container mt-5">
                <div className="datatalent-step-one">
                  <h6 className="mb-3">1. Learn <span style={{fontWeight: "500"}}>- Upskill Through Advanced Courses</span></h6>
                  <p className="datatalent-step-indent">Take <strong>3 advacned course bundles</strong> in Data Science and Machine Learning to enhance your existing skills. These courses are fully online, and can be completed at your own pace with three capstone projects each.</p>
                </div>
                <div className="datatalent-step-one">
                  <h6 className="mb-3">2. Practice <span style={{fontWeight: "500"}}>- Apply Your Skills to a Project</span></h6>
                  <p className="datatalent-step-indent">Apply the skills you’ve learned in the courses as you <strong>work on a project for a real employer</strong> in your desired industry. Complete the project in a small group of 3-4 members.</p>
                </div>
                <div className="datatalent-step-one">
                  <h6 className="mb-3">3. Excel <span style={{fontWeight: "500"}}>- Be Ready for the Tech Industry</span></h6>
                  <p className="datatalent-step-indent">After successfully completing the courses and the employer-led project, you will <strong>receive a $500 stipend</strong>. Depending on performance, employers may also choose to hire you for a full time position!</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              Screenshot of courses.
            </div>
            <div className="col-12 container">
              <div className="row datatalent-steps-footer">
                <div className="col-lg-6 mb-4">
                  <p>
                    <strong>Learn The Latest <span className="highlight-text-primary">Industry-Standard</span> Data Science Skills</strong><br></br>
                    Easy to learn for those with or without a technical background.
                  </p>
                </div>
                <div className="col-lg-2 mb-4 d-flex flex-row align-items-center gap-3">
                  <div className="dsf-icon-container">
                    !
                  </div>
                  <p className="dsf-skill">Machine Learning</p>
                </div>
                <div className="col-lg-2 mb-4 d-flex flex-row align-items-center gap-3">
                  <div className="dsf-icon-container">
                    !
                  </div>
                  <p className="dsf-skill">Machine Learning</p>
                </div>
                <div className="col-lg-2 mb-4 d-flex flex-row align-items-center gap-3">
                  <div className="dsf-icon-container">
                    !
                  </div>
                  <p className="dsf-skill">Machine Learning</p>
                </div>
                <div className="col-lg-3 mb-3 d-flex flex-row align-items-center gap-3">
                  <div className="dsf-icon-container">
                    <p><strong>3</strong></p>
                  </div>
                  <p><strong>Capstone Projects</strong></p>
                </div>
                <div className="col-lg-3 mb-3 d-flex flex-row align-items-center gap-3">
                  <div className="dsf-icon-container">
                    <strong>1</strong>
                  </div>
                  <p><strong>Employer-Led Project</strong></p>
                </div>
                <div className="col-lg-2 mb-3 d-flex flex-row align-items-center gap-3">
                  <div className="dsf-icon-container">
                    !
                  </div>
                  <p className="dsf-skill">Artificial Intelligence</p>
                </div>
                <div className="col-lg-2 mb-3 d-flex flex-row align-items-center gap-3">
                  <div className="dsf-icon-container">
                    !
                  </div>
                  <p className="dsf-skill">Artificial Intelligence</p>
                </div>
                <div className="col-lg-2 mb-3 d-flex flex-row align-items-center gap-3">
                  <div className="dsf-icon-container">
                    !
                  </div>
                  <p className="dsf-skill">Artificial Intelligence</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default JobseekersAbout;