import React, { useState } from 'react';
import { Element } from 'react-scroll';
import DataTalentSectionTitle from '../DataTalentSectionTitle';
import { Link } from 'react-router-dom';

const DataTalentProgramOverview = () => {
  const [showTimeline, setShowTimeline] = useState(true);
  const [showBenefits, setShowBenefits] = useState(false);
  const [showCurriculum, setShowCurriculum] = useState(false);

  const handleTimelineClick = () => {
    document.getElementById("Timeline").classList.add("active-program-overview");
    document.getElementById("Benefits").classList.remove("active-program-overview");
    document.getElementById("Curriculum").classList.remove("active-program-overview");
    setShowTimeline(true);
    setShowBenefits(false);
    setShowCurriculum(false);
  }
  
  const handleBenefitsClick = () => {
    document.getElementById("Timeline").classList.remove("active-program-overview");
    document.getElementById("Benefits").classList.add("active-program-overview");
    document.getElementById("Curriculum").classList.remove("active-program-overview");
    setShowTimeline(false);
    setShowBenefits(true);
    setShowCurriculum(false);
  }
  
  const handleCurriculumClick = () => {
    document.getElementById("Timeline").classList.remove("active-program-overview");
    document.getElementById("Benefits").classList.remove("active-program-overview");
    document.getElementById("Curriculum").classList.add("active-program-overview");
    setShowTimeline(false);
    setShowBenefits(false);
    setShowCurriculum(true);
  }

  return (
    <>
      <Element
        name="programOverview"
        className="edu-about-area about-style-1 datatalent-section-gap datatalent-jobseeker-bg"
      >
        <div className="container inner about-feature-list">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12">
              <DataTalentSectionTitle
                classes = "text-start"
                slogan = "DataTalent Process"
                title = "Program Overview"
              />
            </div>
            <div className="col-12 dpo-nav d-flex flex-row gap-3">
              <div>
                <button className="dpo-nav-item active-program-overview" id="Timeline" onClick={handleTimelineClick}>
                  Timeline
                </button>
              </div>
              <div>
                <button className="dpo-nav-item" id="Benefits" onClick={handleBenefitsClick}>
                  Benefits
                </button>
              </div>
              <div>
                <button className="dpo-nav-item" id="Curriculum" onClick={handleCurriculumClick}>
                  Curriculum
                </button>
              </div>
            </div>
            {showTimeline && <div className="col-12 dpo-details">
              <div className="dpo-timeline row g-5">
                <div className="col-12 col-lg-9">
                  <img src={`${process.env.PUBLIC_URL}/images/data-talent/datatalent-timeline.png`} alt="timeline" />
                </div>
                <div className="col-12 col-lg-3 pt-5 pe-0">
                  <h6>Throughout the Program</h6>
                  <p>As you work through term,<br></br>you will have access to:</p>
                  <ul>
                    <li>1-on-1 <strong>Technical support</strong></li>
                    <li>Resume and cover letter <strong>workshops</strong></li>
                    <li>Strategic <strong>job search</strong> guidance</li>
                  </ul>
                </div>
              </div>
              <div className="dpo-eligibility row g-5 mt--25">
                <div className="col-12 col-lg-6 m-0">
                  <h4><span className="highlight-text-primary">Eligibility</span></h4>
                  <h6>Applicants Must Haves</h6>
                  <ol className="mb-0">
                    <li>Canadian Citizen, PR, Refugee, or Work Permit Holder</li>
                    <li>Attain <strong>3+ years</strong> of work experience <strong>outside</strong> of studies</li>
                  </ol>
                  <span className="datatalent-description-span">*work permit holders must have at least one year left on their permit</span>
                  <p className="mt-3 mb-0"><em><strong>Not eligible for DatTalent? Explore our <Link to="/programs/datatalent/jobseekers"><span className="highlight-text-primary">annual plans here <img src={`${process.env.PUBLIC_URL}/images/icons/arrow-right.svg`} alt="right arrow" /></span></Link></strong></em></p>
                </div>
                <div className="col-12 col-lg-6 dpo-eligibility-experience">
                  <h6>Eligible Work Experience</h6>
                  <div className="dpo-eligibility-experience-wrapper">
                    <p className="mb-0 d-flex align-items-center gap-2"><img src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} width="14px" alt="green check" /> Canadian Work</p>
                    <p className="mb-0 d-flex align-items-center gap-2"><img src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} width="14px" alt="green check" /> International Work</p>
                    <p className="mb-0 d-flex align-items-center gap-2"><img src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} width="14px" alt="green check" /> Volunteer (non-profit) positions</p>
                    <div className="dpo-divider"></div>
                    <p className="mb-0 d-flex align-items-center gap-2"><img src={`${process.env.PUBLIC_URL}/images/icons/cross.svg`} width="14px" alt="red cross" /> Co-operative Education Programs</p>
                    <p className="mb-0 d-flex align-items-center gap-2"><img src={`${process.env.PUBLIC_URL}/images/icons/cross.svg`} width="14px" alt="red cross" /> Internships</p>
                    <p className="mb-0 d-flex align-items-center gap-2"><img src={`${process.env.PUBLIC_URL}/images/icons/cross.svg`} width="14px" alt="red cross" /> Any Work Experience Gained during University Enrollment</p>
                  </div>
                </div>
              </div>
            </div>}
            {showBenefits && <div className="col-12 dpo-details">
              <h5>Benefits</h5>
            </div>}
            {showCurriculum && <div className="col-12 dpo-details">
              <h5>Curriculum</h5>
            </div>}
          </div>
        </div>
      </Element>
    </>
  )
}

export default DataTalentProgramOverview;